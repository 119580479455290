import { createStore } from 'redux'

function reducer(state = {}, action) {
  let ret = { ...state };
  ret[action.type] = action.data;
  return ret;
}

const store = createStore(reducer)

export default store
