import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import './App.scss'
import store from './redux/store'
import history from './history'

const loading = () => (
  <div className='animated fadeIn pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'))
const Register = React.lazy(() => import('./views/Pages/Register/Register'))
const RegisterPass = React.lazy(() => import('./views/Pages/Register/RegisterPass'))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500'))
const RecuperacaoSenha = React.lazy(() => import('./views/Senha/RecuperacaoSenha'))
const AtualizarSenha = React.lazy(() => import('./views/Senha/AtualizarSenha'))
const Calculadora = React.lazy(() => import('./views/Pages/Calculator'))
const NewTreinoUUID2 = React.lazy(() => import('./views/TreinoUUID'))

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path='/login'
                name='Login Page'
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path='/calculoo'
                name='Calculadora'
                render={(props) => <Calculadora {...props} />}
              />
              <Route
                exact
                path='/treino-uuid/:uuid'
                name='Treino Uuid'
                render={(props) => <NewTreinoUUID2 {...props} />}
              />
              <Route
                exact
                path='/cadastro'
                name='Cadastro'
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path='/cadastrar/senha'
                name='Cadastro'
                render={(props) => <RegisterPass {...props} />}
              />
              <Route
                exact
                path='/recuperacao/senha'
                name='Recuperação de senha'
                render={(props) => <RecuperacaoSenha {...props} />}
              />
              <Route
                exact
                path='/atualizar/senha'
                name='Atualizar senha'
                render={(props) => <AtualizarSenha {...props} />}
              />
              <Route exact path='/404' name='Page 404' render={(props) => <Page404 {...props} />} />
              <Route exact path='/500' name='Page 500' render={(props) => <Page500 {...props} />} />
              <Route path='/' name='Home' render={(props) => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </Router>
      </Provider>
    )
  }
}

export default App
